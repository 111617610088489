<template>
  <div class="christmas_lights_wrapper">
    <div class="line"></div>
    <div class="bulb_wrapper">
      <div
        v-for="(bulb, index) in bulbs"
        :key="index"
        class="bulb"
        :style="{ top: calculateTopPosition(index) }"
        @mouseover="toggleSwing(index)"
        @touchstart="toggleSwing(index)"
      >
        <span
          class="glow"
          :style="{ backgroundColor: `rgb(${bulb.color.r}, ${bulb.color.g}, ${bulb.color.b})`, filter: bulb.on ? 'blur(4px)' : 'blur(12px)' }"
        ></span>
        <span
          class="glas"
          :style="{ backgroundColor: `rgb(${bulb.color.r}, ${bulb.color.g}, ${bulb.color.b})` }"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bulbs: [], // Inicializamos el array vacío
      lineHeight: 0,
      counter: 0,
    };
  },
  methods: {
    // Crea las bombillas con colores alternados (rojo y verde)
    createBulbs() {
      const totalBulbs = 24; // Número de bombillas
      this.lineHeight = this.$el.querySelector(".line").clientHeight || 0;

      this.bulbs = Array.from({ length: totalBulbs }, (_, index) => {
        const isRed = index % 2 === 0; // Alterna entre rojo y verde
        return {
          on: true, // Estado inicial: encendido
          offColor: isRed
            ? { r: 150, g: 0, b: 0 } // Color apagado: rojo oscuro
            : { r: 0, g: 150, b: 0 }, // Color apagado: verde oscuro
          onColor: isRed
            ? { r: 250, g: 0, b: 0 } // Color encendido: rojo brillante
            : { r: 0, g: 250, b: 0 }, // Color encendido: verde brillante
          color: isRed
            ? { r: 250, g: 0, b: 0 } // Inicializa en rojo
            : { r: 0, g: 250, b: 0 }, // Inicializa en verde
        };
      });
    },
    // Calcula la posición vertical de las bombillas
    calculateTopPosition(index) {
      const angle = index * (180 / (this.bulbs.length - 1)) * (Math.PI / 180);
      return `${Math.floor((this.lineHeight / 7) * Math.sin(angle))}px`;
    },
    // Cambia el estado (on/off) de una bombilla por su índice
    toggleBulb(index) {
      // Verificar si el índice está dentro del rango
      if (this.bulbs[index]) {
        const bulb = this.bulbs[index];
        bulb.on = !bulb.on;
        bulb.color = bulb.on ? bulb.onColor : bulb.offColor;
      }
    },
    // Añade una animación de columpio a una bombilla
    toggleSwing(index) {
      const bulbElement = this.$el.querySelectorAll(".bulb")[index];
      if (bulbElement) {
        bulbElement.classList.add("swing");
        setTimeout(() => {
          bulbElement.classList.remove("swing");
        }, 2000);
      }
    },
  },
  mounted() {
    // Crear las bombillas al montar el componente
    this.createBulbs();

    // Configurar el intervalo para alternar el estado de las bombillas
    setInterval(() => {
      if (this.counter >= this.bulbs.length) {
        this.counter = 0; // Reiniciar el contador si excede el total
      }
      this.toggleBulb(this.counter);
      setTimeout(() => this.toggleBulb(this.counter), 1200); // Apagar después de 1.2 segundos
      this.counter++;
    }, 150);

    // Ajustar la altura de la línea al cambiar el tamaño de la ventana
    window.addEventListener("resize", () => {
      this.lineHeight = this.$el.querySelector(".line").clientHeight || 0;
    });
  },
};
</script>



<style scoped>
.christmas_lights_wrapper {
  position: fixed;  /* Asegura que esté fijo */
  top: 90px;  /* Se mantiene en la parte superior */
  left: 50%;  /* Centrado horizontalmente */
  transform: translateX(-50%);  /* Asegura que quede centrado */
  z-index: 9999;  /* Asegura que esté encima de otros elementos */
  pointer-events: none; /* Evita que las luces interfieran con la interacción del usuario */
}

@media only screen and (max-width: 768px) {
  .christmas_lights_wrapper {
    top: 150px;  /* Ajusta la distancia superior en pantallas pequeñas */
  }
}

.line {
  position: relative;
  width: 130vw;
  height: 35vw;
  border-bottom: 2px solid black;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -80%);
  margin-bottom: -32.5vw;
}
.bulb_wrapper {
  white-space: nowrap;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}
.bulb::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: 7px solid #586266;
  border-radius: 25%;
}
.bulb {
  position: relative;
  display: inline-block;
  width: 2vw;
  height: 2vw;
  margin: 1vw;
  background: #394347;
  border-radius: 25%;
  transform-origin: center top;
  box-sizing: border-box;
}
.bulb.swing {
  animation: swing 0.5s ease-in-out;
}
.bulb .glow {
  display: block;
  position: relative;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  background: #fff389;
  left: 50%;
  transform: translate(-50%, -35%);
  top: 100%;
  transition: background 0.2s ease-in-out, filter 0.2s ease-in-out;
}
.bulb .glas::before {
  content: '';
  position: absolute;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 9px solid rgba(0, 0, 0, 0.2);
  border-width: 0 0 7px 7px;
  border-radius: 50%;
}
.bulb .glas {
  display: block;
  position: relative;
  width: 2.8vw;
  height: 2.8vw;
  margin-top: -3vw;
  border-radius: 50%;
  background: #fff389;
  left: 50%;
  transform: translate(-50%, -35%);
  top: 100%;
  transition: background 0.2s ease-in-out;
}
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(30deg);
  }
  70% {
    transform: rotate(-15deg);
  }
  90% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>

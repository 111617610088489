<template>
  <div class="tree-div">
  <div class="tree">
    <!-- Estrella -->
    <svg
      class="tree__star"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 113.32 108.44"
      :style="{'--delay': 50}"
    >
      <path
        d="M90.19 104.33L57.12 87.38 24.4 105l5.91-36.69L3.44 42.65l36.72-5.72 16.1-33.5L73.06 36.6l36.83 4.97-26.35 26.21z"
        fill="none"
        stroke-width="6.88"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>

    <!-- Luces -->
    <div
      v-for="(light, index) in lights"
      :key="index"
      class="tree__light"
      :style="{
        '--appear': light.appear,
        '--y': light.y,
        '--rotate': light.rotate,
        '--radius': light.radius,
        '--speed': light.speed,
        '--delay': light.delay
      }"
    ></div>
  </div>
</div>
</template>

<script>
export default {
  name: "Tree",
  data() {
    const LIGHTS = 50;

    // Generar configuración para las luces
    const genConfig = (index) => ({
      rotate: (1440 / LIGHTS) * (LIGHTS - index),
      radius: (12.5 / LIGHTS) * (LIGHTS - index),
      y: (100 / LIGHTS) * index,
      speed: Math.random() * 10,
      delay: Math.random() * -10,
      appear: index,
    });

    // Crear la lista de luces
    const lights = Array.from({ length: LIGHTS }, (_, index) =>
      genConfig(index)
    );

    return {
      lights,
    };
  },
};
</script>

<style scoped>

body {
  background: hsl(210, 32%, 20%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1200px;
  transform-style: preserve-3d;
}

.tree {
  position: relative;
  height: 50vmin;
  width: 25vmin;
  transform-style: preserve-3d;
  animation: spin 2s infinite linear;
}

.tree__light {
  transform-style: preserve-3d;
  position: absolute;
  height: 1vmin;
  width: 1vmin;
  border-radius: 50%;
  animation: flash calc(var(--speed) * 1s) calc(var(--delay) * 1s) infinite steps(4),
    appear 0.5s calc(var(--appear) * 0.05s) both;
  left: 50%;
  transform: translate(-50%, 50%)
    rotateY(calc(var(--rotate, 0) * 1deg))
    translate3d(0, 0, calc(var(--radius, 0) * 1vmin));
  bottom: calc(var(--y, 0) * 1%);
}

.tree__star {
  stroke-width: 5vmin;
  stroke: hsl(45, 80%, 80%);
  filter: drop-shadow(0 0 2vmin hsl(45, 90%, 90%));
  height: 5vmin;
  width: 5vmin;
  overflow: visible !important;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  stroke-dasharray: 1000 1000;
  fill: none;
  animation: stroke 1s calc((var(--delay) * 0.95) * 0.05s) both;
}

@keyframes stroke {
  from {
    stroke-dashoffset: -1000;
  }
}

@keyframes spin {
  to {
    transform: rotateY(360deg);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
}

@keyframes flash {
  0%,
  100% {
    background: red;
  }
  20% {
    background: white;
  }
  40% {
    background: red;
  }
  60% {
    background: white;
  }
  80% {
    background: red;
  }
}
</style>

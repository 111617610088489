export function specialDate() {
    const today = new Date();
    const month = today.getMonth();

    let special = null;
    let specialColor = "";

    if (month === 11) {
        special = { name: "christmas", text: "NAVIDAD", icon: 'snow-outline' };
    }
    else if (month === 9) {
        special = { name: "halloween", text: "HALLOWEEN", icon: '' }
    }
    else if (month === 1) {
        special = { name: "valentines", text: "SAN VALENTIN", icon: '' }
    }
    else if (month === 4) {
        special = { name: "pascuas", text: "PASCUAS", icon: '' }
    }

    if (special) {
        document.documentElement.style.setProperty('--special-color', `var(--${special.name})`);
    }
    return special;
}